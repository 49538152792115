
define('modules/moggler', [
	'jquery',
  'underscore',
  'utils/pubsub',
], function ($, _, pubsub) {
  'use strict';

  return {
    // $el
    // settings
    defaults: {
      event: 'click',
      css: 'test2',
      preventDefault: true,
      once: false,
    },
    init: function () {

    }
  };
});