define(['jquery', 'knockout', 'models/AmountSliderViewModel.js', 'resources/account.js'], function ($, ko, amountSliderViewModel, currentAccountLoad) {
    return currentAccountLoad.then(function (currentAccount) {
        var Class = function (settings) {
            var _this = this;
            settings.baseAmount = settings.baseAmount || currentAccount.balanceValue;
            console.log(settings.baseAmount);
            amountSliderViewModel.call(_this, settings);
            _this.minAmount = (function () {
                console.log("FUCK YOU GULP!")
                var minAccordingToAccount = (Math.ceil(currentAccount.balanceValue / 1000) * 1000) + 3000;
                return minAccordingToAccount > _this.minAmount
                    //if current balance is even with min amount add 1000 to make lowest possible application an actual raise
                    //also, if current balance + 3000 is more than maxamount, use only maxAmount
                    ? minAccordingToAccount <= _this.maxAmount ? minAccordingToAccount : _this.maxAmount
                    : _this.minAmount; 
            })();
            return _this;
        };
        //Class.prototype = $({}, amountSliderViewModel);
        Class.prototype.constructor = Class;
        return Class;
    });
});