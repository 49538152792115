SystemJS.config({ bundles: {
	'/assets/build/loanapplication.js' : [
		'modules/OfferOption.js',
		'modules/KalpForm.js',
		'modules/ComplementStateHandler.js',
		'modules/ComplementForm.js',
		'modules/ApplicationStatusPoller.js',
		'loanapplication/otherloans.js',
		'loanapplication/loanApplication.js',
		'utils/IbanBankResolver.js',
		'utils/queryStringParser.js',
		'utils/accordion.js']
}});