define(['utils/pubsub'],
    function (pubsub) {
        'use strict';
        return {
            defaults: {
                event: null,
                eventId: null,
                url: null,
                replaceUrl: false
            },
            init: function () {
                var self = this;
                pubsub.sub(self.settings.event, function (eventData) {
                    if (!self.settings.eventId || (eventData && eventData.id && eventData.id == self.settings.eventId))
                    {
                        self.settings.replaceUrl && window.location.replace 
                            ? window.location.replace(self.settings.url)
                            : window.location = self.settings.url;
                    }
                    return;
                });
            }
        }
    });