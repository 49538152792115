define(['jquery'], function ($) {
	'use strict';

	var isValidDeferred = function (source) {
	    return typeof source['done'] === 'function'
        && typeof source['fail'] === 'function'
        && typeof source['always'] === 'function';
	}

	return function (validator, deferredValidator, value, element, ruleName) {
		'use strict'
		var previous = validator.previousValue(element),
			data, valid, resolvedValidator;

		if (!validator.settings.messages[element.name]) {
			validator.settings.messages[element.name] = {};
		}
		previous.originalMessage = validator.settings.messages[element.name].remote;
		validator.settings.messages[element.name].remote = previous.message;
        
	    //Check if provided is a deferred or a function returning a deferred
		if (!isValidDeferred(deferredValidator)) {
		    //if deferred is wrapped in function return, check previous value before executing
            //use wrapped deferreds if value comparision to previous value is desired before executing
		    if (typeof deferredValidator === 'function') {
		        if (previous.old === value) {
		            return previous.valid;
		        }
		        resolvedValidator = deferredValidator();
		        if (!isValidDeferred(resolvedValidator)) {
		            throw 'Function passed in argument deferredValidator must be function returning a jQuery promise, current return value of function is ' + typeof resolvedValidator;
		        }
		    }
		    else {
		        //throw 'Parameter passed in argument deferredValidator must be a function or a promise, current type is ' + typeof deferredValidator;
		        //just try to return the value
		        return resolvedValidator;
		    }
		}
		else {
            //Parameter passed is a straight up promise, use it directly
		    resolvedValidator = deferredValidator;
		}

		previous.old = value;
		validator.startRequest(element);
		data = {};
		data[element.name] = value;
	    resolvedValidator.then(function () {
			validator.settings.messages[element.name].remote = previous.originalMessage;
		}).done(function () {
			var submitted = validator.formSubmitted;
			validator.prepareElement(element);
			validator.formSubmitted = submitted;
			validator.successList.push(element);
			delete validator.invalid[element.name];
            validator.stopRequest(element, true);
            validator.showErrors();
            //We need to call this stuff to make input valid again.
            validator.settings.unhighlight.call(validator, element, validator.settings.errorClass, validator.settings.validClass);
			valid = true;
		}).fail(function () {
			var errors = {};
            errors[element.name] = validator.defaultMessage(element, ruleName);
            validator.invalid[element.name] = true;
            validator.stopRequest(element, false);
			validator.showErrors(errors);
			valid = false;
		}).always(function () {
		    previous.valid = valid;
		});
	    return 'pending';
	}
});