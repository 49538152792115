
/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define([
	'jquery'
], function ($) {
	'use strict';

	var myPagesDetails = {
		// $el
		// settings
		defaults: {
		},
		init: function () {
		    var self = this;
		    window.ActivateSmsOnComplete = self.ActivateSmsOnComplete;
		    window.ChangeNumberOnComplete = self.ChangeNumberOnComplete;
		    window.VerifyOnComplete = self.VerifyOnComplete;
		    $('.js-ChangePhoneNunmberLink').click(function (e) {
		        e.preventDefault();
		        $('.js-ChangePhoneNumber').removeClass("u-hidden");
		        $('.js-ChangePhoneNunmberLink').addClass("u-hidden");
		        $('.js-ChangePhoneNumberText').addClass("u-hidden");
		    });

		    $('.js-TryAgainButton input').click(function(e) {
		        e.preventDefault();
		        $(".js-ChangePhoneNumber").removeClass("u-hidden");
		        $(".js-VerifySmsTokens").addClass("u-hidden");
		        $(".js-VerifyError").addClass("u-hidden");
		        $(".js-PhoneError").addClass("u-hidden");
		        $("input[name='PhoneNumber']").removeClass('input-validation-error');
		        $("input[name='Form.Token']").removeClass('input-validation-error');
		        $(".js-TryAgainButton").addClass("u-hidden");
		        $("js-smsToken").val("");

		    });
		},

		ChangeNumberOnComplete: function (response) {
		    if (response.status === 200) {
		        var data = response.responseJSON;
		        if (data.Success) {
		            $('.js-smsauthenticationid').val(data.Token);
		            $('.js-ChangePhoneNumber').addClass("u-hidden");
		            $('.js-VerifySmsTokens').removeClass("u-hidden");
		            $('.js-VerifiedPhoneNumber').text('+'+data.CountryCode + ' ' + data.PhoneNumber);
		        } else {
		            $("input[name='PhoneNumber']").addClass('input-validation-error');
		            $(".js-PhoneError").removeClass("u-hidden");
		        }
		    } else {
		        $("input[name='PhoneNumber']").addClass('input-validation-error');
		        $(".js-PhoneError").removeClass("u-hidden");
		    }
	    },

        VerifyOnComplete: function(response) {
            if (response.status === 200) {
                var data = response.responseJSON;
                if (data.Success) {
                    $('.js-VerifySmsTokens').addClass("u-hidden");
                    $('.js-VerifyComplete').removeClass("u-hidden");
                } else {
                    $("input[name='Form.Token']").addClass('input-validation-error');
                    $(".js-VerifyError").removeClass("u-hidden");
                    $('.js-TryAgainButton').removeClass("u-hidden");
                    $("input[name='Form.Token']").removeClass('valid');
                }
            } else {
                $("input[name='Form.Token']").addClass('input-validation-error');
                $("input[name='Form.Token']").removeClass('valid');
                $(".js-VerifyError").removeClass("u-hidden");
                $(".js-TryAgainButton").removeClass("u-hidden");
            }
        }
	};

	return myPagesDetails;

});