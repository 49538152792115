
define('modules/duplicator', [
	'jquery'
], function ($) {
	'use strict';
	var duplicator = {
		defaults: {
			template: '',
			target: '',
			clearInputs: true,
			calcIndex: '.js-existingLoans'
		},
		init: function ($el, settings) {
			var self = this,
				bracket_rx = /[\d+]/,
				underscore_rx = /_\d+_/,
				$target = $(settings.target),
				$template = $(settings.template),
				index = 0;

			$el.on('click', function () {
			  var $copy = $template.clone(false);
			  if (settings.calcIndex) {
			    index = $(settings.calcIndex).length;
			  }else{
			    index++;
			  }

				//loop over all fields that can have a name attribute and try to set a new version if it contains indexer
				//ASP.NET needs collections to start from index 0 and increment by one for each new item
				$.each($copy.find('input, select, option, textarea, label'), function () {
				  var $t = $(this),
						name = $t.prop('name'),
						id = $t.prop('id'),
						_for = $t.prop('for');


					if (name) {
						name = name.replace(bracket_rx, index);
						$t.prop('name', name);
					}
					
					if (id) {
						id = id.replace(underscore_rx, '_' + index + '_');
						$t.prop('id', id);
					}
				
					if (_for) {
						_for = _for.replace(underscore_rx, '_' + index + '_');
						$t.prop('for', _for);
					}
					if (settings.clearInputs && $t.is('input')) {
					  $t.val('');
					}
				});
				$target.append($copy);
				$target.trigger('duplicated', $copy);
				
			});
		}
	};
	return duplicator;
});