define('modules/FileUpload', ['jquery'], function ($) {
    'use strict';
    return {
        defaults: {
            loaderElement: null,
            hideLoadElement: null
        },
        init: function ($el, settings) {
            var $wrapper = $el,
                $label = $wrapper.find('label'),
                $input = $wrapper.find('input[type="file"]'),
                $icon = $wrapper.find('i.Icon'),
                $txt = $wrapper.find('span.file-name'),
                fallbackTxt = $txt.text();

            $input.on('duplicate', function (e) {
                showWarning(this);
            });

            $input.on('change', function (e) {
                if ($(this).prop('is-duplicate')) {
                    $(this).prop('is-duplicate', false);
                    return;
                }
                else if (this.value == "") {
                    showWarning(this);
                    return;
                }
                else {
                    var name = this.value.split(/(\\|\/)/g).pop();
                    $label.removeClass('is-empty');
                    $wrapper.removeClass('warning');
                    $icon.addClass('Icon--check');
                    $txt.text(name);
                }
            });

            function showWarning(input) {
                input.value = "";
                $txt.html(fallbackTxt);
                $(input).prop('is-duplicate', true)
                $wrapper.addClass('warning');
            }


        }
    };
});