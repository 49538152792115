define('modules/MultipleFileUpload', ['jquery'], function ($) {
    'use strict';
    return {
        defaults: {
            duplicateFoundText: 'Du får inte ladda upp samma fil två ggr',
            validationElement: '[data-valmsg-for="incomeStatement"]',
            maxCount: 3

        },
        init: function ($el, settings) {
            var names = [],
                $wrapper = $($el),
                $input = $wrapper.find('input[type="file"]');


            $input.on('change', function (e) {
                var val = this.value,
                    names = $input.map(function (i, e) { return e.value }).toArray().filter(function (x) { return x != "" });

                console.log(names);


                if (occurs(names, val) > 1) {
                    $(this).trigger('duplicate');
                    return;
                }
                if (names.length == settings.maxCount) {
                    $wrapper.trigger('max-count', { len: names.length });
                }
            });
            function occurs(array, what) {
                var count = 0;
                for (var i = 0; i < array.length; i++) {
                    if (array[i] === what) {
                        count++;
                    }
                }
                return count;
            }
        }
    };
});