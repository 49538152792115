
/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define([
	'jquery',
    'utils/pubsub'
], function ($, pubsub) {
    'use strict';

    var Poller = {
        // $el
        // settings
        defaults: {
            url: null,
            interval: 60,
            event: null,
            data: {},
            method: 'get',
            pollOnInit: false,
            publisherId: '',
            initDelay: 0,
            useGlobalErrorHandling: true
        },
        init: function ($el, settings, deferred) {
            var self = this;
            if(self.settings.pollOnInit)
            {
                window.setTimeout(function () {
                    Poller.poll(self);
                }, self.settings.initDelay);
            }
            if (self.settings.interval) {
                window.setInterval(function () { Poller.poll(self); }, self.settings.interval * 1000);
            }
            if(self.settings.event)
            {
                self.$el.on(self.settings.event, function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    Poller.poll(self);
                });
            }
        },

        poll: function (self) {
            $.ajax({
                url: self.settings.url,
                type: self.settings.method,
                data: self.settings.data,
                globa: self.settings.useGlobalErrorHandling
            }).done(function (data) {
                pubsub.pub("pollerDone", [{
                    id: self.settings.publisherId,
                    data: data,
                    $triggerEl: self.$element,
                }])
            }).fail(function (data) {
                pubsub.pub("pollerFail", [{
                    id: self.settings.publisherId,
                    data: data,
                    $triggerEl: self.$element,
                }])
            });
        },

    };

    return Poller;

});