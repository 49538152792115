
/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define([
    'jquery',
    'utils/pubsub'
], function ($, pubsub) {
    'use strict';

    var Toggler = {
        // $el
        // settings
        defaults: {
            event: 'click',
            css: '',
            secondaryCss: '',
            preventDefault: true,
            once: false,
            slow: true,
            toggleElement: null,
            removeSelf: false,
            attr: null,
            val: null
        },
        init: function ($el, settings, deferred) {
            var self = this;

            if (self.settings.slow) {
                setTimeout(function () { deferred.resolve(); }, 1000);
            } else {
                deferred.resolve();
            }

            pubsub.sub("controllers.ready", self._allReady);

            if (self.settings.once) {
                self.$el.one(self.settings.event, function (e) { self.toggle(self, e) });
            } else {
                self.$el.on(self.settings.event, function (e) { self.toggle(self, e) });
            }
        },

        toggle: function (self, e) {

            if (self.settings.preventDefault) {
                e.preventDefault();
            }
            var $toggleElement = self.settings.toggleElement ? $(self.settings.toggleElement) : self.$el;
            if (self.settings.attr) {
                self.toggleAttr(self, e);
            }
            else {
                $toggleElement.toggleClass(self.settings.css);
                $toggleElement.toggleClass(self.settings.secondaryCss);
            }
            if (self.settings.removeSelf) {
                self.$el.remove();
            }
        },

        _allReady: function () {

        },
        toggleAttr: function(self, e){
            var $toggleElement = self.settings.toggleElement ? $(self.settings.toggleElement) : self.$el;
            var val = $toggleElement.attr(self.settings.attr);
            //if we allready have set the desired value, then remove it
            if (val == self.settings.val || self.settings.css) {
                $toggleElement.attr(self.settings.attr, '');
            }
            else {
                $toggleElement.attr(self.settings.attr, self.settings.val || self.settings.css);
            }
        }
    };

    return Toggler;

});