define(['jquery','BankId/BankIdTransaction.js', 'utils/pubsub'], function ($, BankIdTransaction, pubsub) {
    'use strict';
    return {
        defaults: {
            event: 'click',
            loginTokenField: '.js-loginTokenField',
            orderRef: null,
            bankIdMessageElem: null,
        },

        init: function ($el, settings, deferred) {
            var _this = this;
            $el.on(settings.event, function (e) { return _this.authenticate.call(_this, e); });
            if (_this.settings.orderRef) {
                _this.setBankIdMessageState.call(_this);
                new BankIdTransaction().collectAuth(_this.settings.orderRef);
            }
        },

        authenticate: function (e) {
            var _this = this;
            e.preventDefault();
            var personalNumber;
            if (_this.settings.personalNumberInput) {
                if (!$(_this.settings.personalNumberInput).valid()) {
                    return;
                }
                personalNumber = $(_this.settings.personalNumberInput).val();
            }
            _this.setBankIdMessageState.call(_this);
            var bankIdAuth = new BankIdTransaction();
            bankIdAuth.auth(personalNumber)
                .then(function (status) {
                    if (status.progress == 'COMPLETE') {
                        return _this.postForm.call(_this, status.orderRef);
                    }
                })
            //if page is reloaded, polling in current (unloading) window needs to stop immediatly.
            //added for IOS which performs a refresh when redirecting back to the browser from the app.
            //$(window).unload(function (event) {
            //    pubsub.pub("BANKID_CANCEL");
            //});
        },

        postForm: function (orderRef) {
            $(this.settings.loginTokenField)
                .val(orderRef)
                .closest('form')
                .removeData('validator')
                .submit();
        },

        //sets the state for related BankID message displays. Depending on which auth action is triggered, we wan't to disable/enable different bankid message display modules.
        // Sort of a hack, but any other options would require heavy refactoring to the BankID transaction flow, and we're currently not commiting to that.
        setBankIdMessageState: function() {
            if (this.settings.bankIdMessageElem == null) {
                return;
            }
            $('.js-bankIdMessage').data('enabled', false);
            $(this.settings.bankIdMessageElem).data('enabled', true);
        }
    };
});