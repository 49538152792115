
define([
	'jquery',
  'utils/pubsub',
], function ($, pubsub) {
  'use strict';

  return {
    // $el
    // settings
    defaults: {
      event: 'click',
      preventDefault: true,
      once: false,
      eventName: null // set event name
    },
    init: function ($el, settings) {
      var self = this;
      if(!settings.eventName)
        throw Error('no eventname set for publisher')
      if (settings.once) {
        $el.one(settings.event, self.publish);
      } else {
        $el.on(settings.event, self.publish);
      }
    },
    publish: function () {
      var self = this;
      pubsub.pub(self.settings.eventName, self.$el);
    }
  };
});