
/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define([
  'jquery',
  'knockout',
  'utils/observable.js',
  'models/LoanConfiguration.js',
  'utils/pubsub'
], function ($, ko, asyncDependentObservable, loanConfig, pubsub) {
    'use strict';

    function CachedDeferred() {
        this.loanDict = {};
        this.get = function (cacheKey, inner) {
            var cachedValue = this.loanDict[cacheKey];
            if (typeof cachedValue !== 'undefined') {
                return cachedValue;
            }
            var result = inner();
            this.loanDict[cacheKey] = result;
            return result;
        }
    }

    /*
       input values: 
       {
          amount : 1000,
          numberOfMonths: 12,
          intrestRate: 33.7,
          admissionFee: 12,
          minAmount : 1000,
          maxAmount: 3000,
          minMonths: 12,
          maxMonths: 36
       }
  
    */
    return function (defaultValues) {
        'use strict';
        var amount = ko.observable(defaultValues.amount || defaultValues.maxAmount),
            formattedAmount = ko.observable(defaultValues.amount),
            formattedMonthlyCost = ko.observable(defaultValues.formattedMonthlyCost),
            numberOfMonths = ko.observable(defaultValues.numberOfMonths || defaultValues.maxMonths),
            intrestRate = ko.observable(defaultValues.intrestRate),
            admissionFee = ko.observable(defaultValues.admissionFee),
            sliderAmount = ko.observable(defaultValues.amount),
            apr = ko.observable(defaultValues.apr),
            monthlyFee = ko.observable(" "),
            totalAmount = ko.observable(0),
            totalCredit = ko.observable(0),
            loading = ko.observable(true),
            disbursment = ko.observable(0),
            monthlyCostCache = new CachedDeferred(),
            calculationUrl = defaultValues.useExternalCalculation
                ? loanConfig.loanCostUrlExternal : loanConfig.loanCostUrl;

        var monthlyCostCalculation = function () {
            var currentKey = "Amount:" + amount().toString() + "Months:" + numberOfMonths().toString();
            return monthlyCostCache.get(currentKey,
                function () {
                    //window.setTimeout(function () {
                    //    if (waiting) {
                    //        loading(true);
                    //    }
                    //}, 200);
                    loading(true);
                    return $.getJSON(calculationUrl, { amount: amount(), numberOfMonths: numberOfMonths(), baseAmount: defaultValues.baseAmount || 0 })
                })
                .then(function (data) {
                    if (currentKey === "Amount:" + amount().toString() + "Months:" + numberOfMonths().toString()) {
                        loading(false);
                        intrestRate(data.InterestRate);
                        admissionFee(data.FormattedApplicationFee);
                        apr(data.EffectiveInterestRate);
                        monthlyFee(data.FormattedMonthlyFee);
                        totalCredit(data.FormattedTotalCredit);
                        totalAmount(data.FormattedTotalCost);
                        formattedAmount(data.FormattedAmount);
                        formattedMonthlyCost(data.FormattedMonthlyCost);
                        disbursment(data.Disbursment);
                        return data.FormattedRepaymentAmount;
                    }
                });
        };

        var monthlyCost = asyncDependentObservable(monthlyCostCalculation, this);

        var updateSliderAmount = function (newAmount) {
            newAmount = newAmount ? newAmount : amount();
            sliderAmount(newAmount);
            pubsub.pub("updaterangeslider", [true]);
        }

        var calculationData = ko.computed(function () {
            var data = {
                totalAmount: totalAmount(),
                totalCredit: totalCredit(),
                admissionFee: admissionFee(),
                amount: amount(),
                numberOfMonths: numberOfMonths(),
                interestRate: intrestRate(),
                apr: apr(),
                monthlyCost: monthlyCost(),
                numberOfYears: numberOfMonths(),
                formattedAmount: formattedAmount(),
                formattedMonthlyCost: formattedMonthlyCost()
            };
            return JSON.stringify(data);
        });
        this.amount = amount;
        this.sliderAmount = sliderAmount;
        this.updateSliderAmount = updateSliderAmount;
        this.numberOfMonths = numberOfMonths;
        this.monthlyCost = monthlyCost;
        this.intrestRate = intrestRate;
        this.admissionFee = admissionFee;
        this.disbursment = disbursment;
        this.minAmount = defaultValues.minAmount;
        this.maxAmount = defaultValues.maxAmount;
        this.minMonths = defaultValues.minMonths;
        this.maxMonths = defaultValues.maxMonths;
        this.calculationData = calculationData;
        this.apr = apr;
        this.totalAmount = totalAmount;
        this.totalCredit = totalCredit;
        this.monthlyFee = monthlyFee;
        this.loading = loading;
        this.formattedAmount = formattedAmount;
        this.formattedMonthlyCost = formattedMonthlyCost;
    };
});