define(['jquery', 'utils/ErrorHandler.js', 'utilities/app.validate.unobtrusive.init.js'],
    function ($, ErrorHandler) {
        'use strict';
        //Setup csrf protection by attaching token to header for every request.
        var requestContextVariables = window.requestContextVariables;
        if (requestContextVariables == null) {
            return;
            //throw("requestContextVariables needs to be in window to setup jquery");
        }
        $.ajaxSetup({
            headers: {
                'X-Request-Verification-Token': requestContextVariables.csrfToken,
                'X-Mock-MyPages-Data': requestContextVariables.mockMyPagesData
            }
        });

        $(document).ajaxError(function(event, jqxhr, settings, thrownError){
          new ErrorHandler().handle(jqxhr);
        });
});