// 
// Application initializer
//window.define = System.amdDefine;
define('app',[
    'moduler',
    'utilities/jquery.config.js'
    //'promise-polyfill'
], function (moduler, jqueryConfig, promisePolyfill, fetch) {

    // Application has initialized, setup moduler
    //support multiple versions of moduler
    var modulerInitializer = moduler.init || moduler.initialize;
    modulerInitializer();

    //Promise polyfill is needed for webpack chunk loading
    if (!window.Promise && promisePolyfill) {
        window.Promise = promisePolyfill;
    } 
});