define('modules/signInitiator', [ 'jquery'], function ($) {
    'use strict';
    return {
        defaults: {
            loaderElement: null,
            hideLoadElement: null,
        },
        init: function () {
            var self = this;
            self.$el.on('submit', function (event) {
                event.preventDefault();
                $.post(self.$el.attr('action'), self.$el.serialize()).done(function (data) {
                    $(data).appendTo($('body')).submit();
                })
                $(self.settings.hideLoadElement).addClass('u-hidden');
                $(self.settings.loaderElement).removeClass('u-hidden');
            });
        }
    };
});