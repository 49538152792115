/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define([
	'jquery',
    'utils/pubsub'
], function ($, pubsub) {
    'use strict';

    var clientIdPopulator = {
        defaults: {
            pubEvent: null
        },
        init: function ($el, settings) {
            var timeout = window.setTimeout(function () { clientIdPopulator.clientIdSet($el, settings) }, 100);
        },
        clientIdSet:  function ($el, settings) {
            if ('ga' in window && typeof ga.getAll === 'function') {
                var tracker = ga.getAll()[0];
                $el.val(tracker.get('clientId'));
                if (settings.pubEvent) {
                    pubsub.pub(settings.pubEvent);
                }
            }
            else {
                window.setTimeout(function () { clientIdPopulator.clientIdSet($el, settings) }, 100);
            }
        }
    };
    return clientIdPopulator;
});