define(['jquery', 'knockout'], function ($, ko) {
    return {
        defaults: {
            messageId: null
        },

        init: function () {
            var _this = this;
            if (_this.settings.messageId == null) {
                throw new Error('MessageId must be set on BindMessage module');
            }
            //also mark message as read async
            $.ajax({
                url: '/api/messages/' + _this.settings.messageId,
                method: 'PUT'
            });

            return $.getJSON('/api/messages/' + _this.settings.messageId)
                .then(function (data) {
                    ko.applyBindings(data, _this.el);
                });
        }
    }
});