define(['jquery', 'jquery.validate'], function ($, modal) {
    'use strict';
    return {
        defaults: {
            toggleClass: null,
            toggleElement: null,
        },

        init: function ($el, settings) {
            var self = this
            self.$el.on('submit', function (event) {
                if (self.$el.valid()) {
                    self.$el.find('input[type="submit"], button').prop({ 'disabled': 'disabled' });
                    if (self.settings.toggleClass) {
                        var toggleElement = self.settings.toggleElement ? $(self.settings.toggleElement) : self.$el;
                        toggleElement.toggleClass(self.settings.toggleClass);
                    }
                }
            });
        }
    }
});