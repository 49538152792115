
define('modules/navigation', [
	'jquery',
  'underscore',
  'utils/pubsub',
], function ($, _, pubsub) {
  'use strict';

  return {
    // $el
    // settings
    defaults: {
      event: 'click',
      css: 'test2',
      preventDefault: true,
      once: false,
      bodyCanvas: '.js-bodyCanvas'
    },
    init: function () {
      var self = this;
      pubsub.sub('open-leftmenu', self.open);
      self.$el.on('click', '.js-close', self.close);
    },
    open: function(){
      var self = this;
      self.$el.addClass('is-active');
      $(self.settings.bodyCanvas).addClass('is-inactive');
      
    },
    close: function () {
      var self = this;
      self.$el.removeClass('is-active');
      $(self.settings.bodyCanvas).removeClass('is-inactive');
    }
  };
});