define(['jquery'], function ($) {

    BankIdError = function (message, progress) {
        Error.call(this, message);
        this.progress = progress;
    };

    BankIdError.prototype = $.extend({}, Error.prototype);
    BankIdError.prototype.constructor = BankIdError;

    var self = {
        sign: function sign(applicationId) {
            return Promise.resolve($.ajax({ method: 'POST', url: '/api/bankid/application/sign/' + applicationId }))
                .catch(function (err) {
                    throw new BankIdError('Error calling BankId endpoint');
                }).then(function (response) {
                    if (response.fault !== 'None') {
                        throw new BankIdError('Fault returned from BankId endpoint', response.fault);
                    }
                    return response;
                });
        },

        collectSign: function collectSign(applicationId, orderRef) {
            return $.ajax({ method: 'GET', url: '/api/bankid/application/sign/' + applicationId + '/' + orderRef }).promise()
                .then(function (status) {
                    return status.fault == 'None' ? status.progressStatus : status.fault;
                }, function () {
                    throw new BankIdError('Error calling BankId endpoint');
                });
        },

        auth: function auth(personalNumber) {
            return Promise.resolve($.ajax({ method: 'POST', url: '/api/bankid/auth/', data: { '': personalNumber || '' } }))
                .catch(function (data) {
                    throw new BankIdError('Error calling BankId endpoint', data.status)
                });
        },

        collectAuth: function collectAuth(orderRef) {
            return Promise.resolve($.ajax({ method: 'GET', url: '/api/bankid/auth/' + orderRef }))
                .then(function (status) {
                    return status.progress;
                })
                .catch(function (data) {
                    throw new BankIdError('Error calling BankId endpoint', data.responseJSON.status);
                });
        }
    }
    return self;
});