define(["knockout", "jquery", "utils/ErrorHandler.js"], function (ko, $, ErrorHandler) {
    return {
        defaults: {
            resourceName: null,
            matchLoaderHeight: false
        },
        init: function () {
            var self = this;
            if (!self.settings.resourceName)
            {
                return;
            }

            var $loader = self.$loader = self.$el.next('.js-loader');
            //Hide html and show loader if adjacent loader element
            if ($loader.length) {
                var elemHeight = self.$el.outerHeight();
                self.$el.addClass('u-hidden');
                $loader.removeClass('u-hidden');
                if (self.settings.matchLoaderHeight) {
                    $loader.css({ 'min-height': elemHeight });
                }
            }

            var systemImport = function (resourceName) {
                return System['import']('resources/' + resourceName + '.js');
            }

            var importFunction = function (resourceName, thenFunction) {
                return thenFunction.call(self, self.settings.resourceName instanceof Array
                        ? Promise.all(self.settings.resourceName.map(systemImport))
                            .then(function (resources) {
                                return resources.reduce(function (result, resource) {
                                    return $.extend(result, resource);
                                }, {})
                            })
                        : systemImport(resourceName));
            }
            importFunction(self.settings.resourceName, this.bindResource);
        },

        bindResource: function (resource) {
            var self = this;
            Promise.resolve(resource)
                .then(function (data) {
                    //only show element and databind if request has content
                    if (typeof data !== 'undefined' && data !== null) {
                        self.$el.removeClass('u-hidden');
                        ko.applyBindings(data, self.$el.get(0));
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    new ErrorHandler(self.$el.parent()).handle(error)
                })
                .then(function () {
                    if (self.$loader.length) {
                        self.$loader.remove();
                    }
                });
        },


    }
});