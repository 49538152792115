
define([
	'jquery'
], function ($) {
	'use strict';
	var fancySelect = {
		defaults: {
			selector: 'select', //the select selector
			text: 'Valitse', // the default text before anything is chosen
			statusCssClass: 'fancySelect-completed',
            inputClass: null //css class to add to input field
		},
		init: function () {
			var self = this,
				settings = self.settings,
				$selectLists = $(settings.selector);

			var $select = self.$el,
				$wrap = $('<div class="Select-wrapper" />'),
				$valuePlaceholder = $('<input type=text />').attr('tabindex', '-1'),
				$focusPlaceholder = $('<span></span>'),
				$opt = $('<option selected />');

			if ($select.hasClass(this.defaults.statusCssClass)) {
				$valuePlaceholder = $select.prev();
				$focusPlaceholder = $select.next();
			}
			else {
				$select.wrap($wrap);
				$valuePlaceholder.insertBefore($select);
				$focusPlaceholder.insertAfter($select);
			}

			var selectedValue = $select.val();
			//now: check for the selected value
			//could be better to: check if there is a default option (value='')
			var text = $select.children("option[value='" + selectedValue + "']").text();
			if (!text) {
				text = settings.text;
				//insert the default text as an option
				$opt.text(text).prependTo($select);
			}
			$valuePlaceholder.val(text);

			if ($select.prev().is($valuePlaceholder) && $select.next().is($focusPlaceholder)) {
				$select.addClass(this.defaults.statusCssClass);
			}

			//add event listener if element has none for change
			var events = $._data($select[0]).events;
			if (typeof events !== 'object' || (typeof events == 'object' && !('change' in events))) {
				this.addEventListener($select, 'change', $valuePlaceholder);
			}
			if(settings.inputClass)
			{
			    $select.addClass(settings.inputClass);
			}
		},

		addEventListener: function ($select, event, $placeholder) {
			$select.on(event, function () {
				var $option = $('option:checked', $select);
				$placeholder.val($option.text());
			});

		}
	};
	return fancySelect;
});