define(['jquery', 'utils/jquery.validate.deferred.js', 'jquery.validate.unobtrusive.ajax'], function ($, jqueryDeferredValidation) {
    var validator = $.validator;
    var pinChecksums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y']

    validator.addMethod("mustbetrue", function (value, element, param) {
        return element.checked;
    });

    validator.addMethod("finnishpnr", function (value, element, param) {
        if (!value) {
            return true;
        }
        //ssnString should be in the form DDMMYY C NNN X 
        //DD = day, MM = month, YY = year C = century, NNN = personal_id, X = checksum

        //sanitize the string
        var parsedValue = value.replace(" ", "").toUpperCase();

        if (parsedValue.length != 11) {
            return false;
        }

        //get DoB, century and personal ID
        var dob = parsedValue.substr(0, 6);
        var centuryId = parsedValue.substr(6, 1); // can be -/+/A
        var pid = parsedValue.substr(7, 3);

        //get the last char which is the checksum
        var checksumChar = parsedValue.slice(-1);
        var pnr = parseInt(dob + pid);
        if (!pnr) {
            return false;
        }
        var checksumPos = pnr % 31;
        //match the value from mod 31 with our checksum
        return pinChecksums[checksumPos] == checksumChar;
    });

    validator.addMethod("iban", function (value, element, param) {
        $(element).data('validatingValue', value);
        var jqueryValidator = this;
        var validatorFunction = function () {
            var deferred = $.Deferred();
            var prevValue;
            window.setTimeout(function () {
                if ($(element).data('validatingValue') !== value) {
                    //We probably should do something here, but let's try not.
                    //jqueryValidator.previousValue(element).valid ? deferred.resolve() : deferred.reject();
                }
                else {
                    $.get('/api/validation/iban', { value: value })
                        .done(function (data) { data === true ? deferred.resolve() : deferred.reject(); })
                        .fail(function () { deferred.reject(); });
                }
            }, 200);
            return deferred;
        }
        return jqueryDeferredValidation(this, validatorFunction, value, element, 'iban')
    });

    validator.addMethod("swedishbankaccount", function (value, element, param){
        $(element).data('validatingValue', value);
        
        var jqueryValidator = this;
        var validatorFunction = function () {
            var deferred = $.Deferred();
            var prevValue;
            window.setTimeout(function () {

                if ($(element).data('validatingValue') !== value) {
                    //We probably should do something here, but let's try not.
                    //jqueryValidator.previousValue(element).valid ? deferred.resolve() : deferred.reject();
                }
                else {
                    var clearingNumberElement = $($(element).data("clearingnumberelement"));
                    if (!clearingNumberElement.val()) {
                        return false;
                    }
                    $.get('/api/validation/bankaccount', { bankAccount: value, clearingNumber: clearingNumberElement.val() })
                        .done(function (data) {
                            if (data === true) {
                                clearingNumberElement.removeClass('input-validation-error').addClass('valid');
                                deferred.resolve();
                                return;
                            };
                            clearingNumberElement.addClass('input-validation-error').removeClass('valid');
                            deferred.reject();
                        })
                        .fail(function () {
                            clearingNumberElement.addClass('input-validation-error').removeClass('valid');
                            deferred.reject();
                        });
                }
            }, 200);
            return deferred;
        }
        return jqueryDeferredValidation(this, validatorFunction, value, element, 'swedishbankaccount')
    });

    validator.addMethod("swedishidentitynumber", function (value, element, param) {
        if (this.optional(element)) {
            return true;
        }

        var number = value.trim().replace("-", "");

        if (number.length != 10 && number.length != 12) {
            return false;
        }

        if (isNaN(number)) {
            return false;
        }

        var socialSecurityNumber = (number.length == 10) ? number : number.substr(2, 10);

        var n;
        var sum = 0;
        for (var i = 0; i < 9; i++) {
            n = parseInt(socialSecurityNumber[i]);
            n = (i % 2 == 0) ? n * 2 : n;
            if (n > 9) {
                n = 1 + (n % 10);
            }
            sum += n;
        }
        var checkSum = 10 - (sum % 10);
        if (checkSum == 10) {
            checkSum = 0;
        }
        return checkSum == socialSecurityNumber[socialSecurityNumber.length - 1];
    });

    validator.addMethod('validatedphonenumber', function (value, element, param) {
        $(element).data('validatingValue', value);
        var jqueryValidator = this;
        var validatorFunction = function () {
            var deferred = $.Deferred();
            var prevValue;
            window.setTimeout(function () {
                if ($(element).data('validatingValue') !== value) {
                    //We probably should do something here, but let's try not.
                    //jqueryValidator.previousValue(element).valid ? deferred.resolve() : deferred.reject();
                }
                else {
                    $.get('/api/validation/phonenumber', { phoneNumber: value, mobile: param })
                        .done(function (data) { data === true ? deferred.resolve() : deferred.reject(); })
                        .fail(function () { deferred.reject(); });
                }
            }, 500);
            return deferred;
        }
        return jqueryDeferredValidation(this, validatorFunction, value, element, 'validatedphonenumber')
    });

    validator.unobtrusive.adapters
        .addBool("iban")
        .addBool("finnishpnr")
        .addBool("mustbetrue")
        .addBool("swedishidentitynumber")
        .addBool("validatedphonenumber")
        .addBool("swedishbankaccount");

    //return validator;
});
