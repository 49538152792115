define('modules/validate', [
  'jquery',
  'underscore'
], function ($, _) {
    'use strict';
    var timer = null;
    var loanValidation = {
        defaults: {
            validateElements: [],
            triggerElement: '',
            triggerEvent: 'click',
            enableRemoteValidation: false,
            alwaysPreventDefault: false,
            disableWhileValidating: true
        },

        init: function ($el, settings, deferred) {
            var self = this;
            settings.triggerElement = settings.triggerElement ? $(settings.triggerElement) : $el;
            $($el).on(settings.triggerEvent, settings.triggerElement, function (event) {
                if (settings.disableWhileValidating) {
                    $el.attr("disabled", "disabled");
                }
                if (settings.alwaysPreventDefault) {
                    event.preventDefault();
                }
                self._validate($el, settings).done(function () {
                    settings.triggerElement.trigger("validated-" + settings.triggerEvent);
                    return true;
                })
                .fail(function (errorData) {
                    event.preventDefault();
                    _.map(settings.validateElements, function (validateElement) {
                        var inp = $(validateElement).find('.input-validation-error:first').get(0);
                        if (inp) {
                            inp.focus();
                        }
                    });
                    $(errorData.elements).each(function (index, data) { if ('trigger' in data) { data.trigger("validationError") } });
                    return false;
                })
                .always(function () {
                    if (settings.disableWhileValidating) {
                        $el.removeAttr("disabled", "disabled");
                    }
                });
            });
        },

        _findInputs: function (validateElement) {
            return $(validateElement).find("input, select")
        },

        _validate: function ($el, settings) {
            var dfd = new $.Deferred();
            var promises = this._clientValidation(settings.validateElements);
            if (settings.enableRemoteValidation) {
                promises = promises.concat(this._remoteValidation(settings.validateElements));
            }
            $.when.apply($, promises)
                .always(function () {
                    var validationData = arguments;
                    if (_.some(validationData, function (data) { return data && 'element' in data })) {
                        dfd.reject({
                            //    inputs: _.map(invalidInputs, function (invalid) { return invalid.input; }),
                            elements: _.map(validationData, function (data) { return data.element; })
                        })
                    }
                    else {
                        dfd.resolve();
                    }
                });
            return dfd;
        },

        _remoteValidation: function (validateElements) {
            var self = this;
            return _.map(validateElements, function (element) {
                var dfd = new $.Deferred();
                var $element = $(element);
                var url = $element.data('validate-url')
                if (url) {
                    var inputs = self._findInputs(element);
                    var data = inputs.serialize();
                    $.post(url, data).done(function (res) {
                        // enable if we want validation of this
                        if (_.isArray(res.errors)) {
                            $element.parents('form').validate().showErrors(
                              _.object(
                                _.map(
                                   _.filter(res.errors, function (error) {
                                       return $("[name='" + error.Key + "']").val().length > 0;
                                   }), function (error) {
                                       return [error.Key, error.Value];
                                   }))
                            );
                            dfd.reject({ element: $element })
                        }
                        else {
                            dfd.resolve();
                        }
                    });
                }
                else {
                    dfd.resolve();
                }
                return dfd;
            });
        },


        _clientValidation: function (validateElements) {
            var self = this;
            var deferreds = _.flatten(_.map($(validateElements), function (section) {
                return _.map(self._findInputs(section), function (input) {
                    var dfd = new $.Deferred();
                    var $input = $(input);
                    if (!$input.valid()) {
                        dfd.reject({ input: input, element: $(section) });
                    }
                    else {
                        dfd.resolve();
                    }
                    return dfd;
                });
            }));
            return deferreds;
        }
    };
    return loanValidation;
});
