/* Add global:false to your ajax settings to not run this module on ajax failures */
define([
	'jquery',
  'underscore',
  'utils/Alert.js'
], function ($, _, Alert) {
  'use strict';
  var ErrorHandler = function ($displayTarget, settings) {
    this.$displayTarget = $displayTarget;
    settings = this.settings = $.extend({}, ErrorHandler.prototype.defaults, settings);
    this.init(settings);
  };

  ErrorHandler.prototype = {
    defaults: {
      inline : false
    },

    init: function (settings) {
      var self = this;
      _.bindAll(self, 'handle');
    },
    handle: function (xhr) {
      var self = this;
      var displayTime = self.$displayTarget === null ? 30000 : null;
      if (xhr && xhr.responseJSON && xhr.responseJSON.error) {
        new Alert({ destination: self.$displayTarget }).show(xhr.responseJSON.error, displayTime);
      }
      else if (xhr.status === 500 || xhr.status === 403 || xhr.status === 401) {
        $.getJSON('/api/translations').then(function (translations) {
          switch (xhr.status) {
            case 401:
            case 403:
              new Alert({ destination: self.$displayTarget }).show(translations.unauthorizedErrorMessage, displayTime);
              break;
            default:
              new Alert({ destination: self.$displayTarget }).show(translations.generalErrorMessage, displayTime);
          }
        });
      }
    }
  };
  return ErrorHandler;
});
