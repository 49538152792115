define('models/LoanConfiguration', [
], function () {
  
  var baseUrl = CashBuddy.config.baseUrl;

  return {
    baseUrl: baseUrl,
    loanCostUrl: baseUrl + 'LoanCost',
    loanCostUrlExternal: '/LoanCost',
    resolveBankFromIbanUrl: baseUrl + 'ResolveBankFromIban',
    errorPageUrl: CashBuddy.config.errorPageUrl
  };
});

