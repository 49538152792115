/*
 * Publish Subscriber Utility
 *
 */

define([], function () {
	'use strict';

	var UNDEFINED,
	  /** @type {Object} A place to store all our subscribers */
	  catalog = {},
	  /** @type {Object} A place to store the latest publisher of respective event */
	  log = {};

	/**
	 * Utility function to check wheather an object is an array or not
	 * @return {Boolean}     Is the variable an array?
	 */
	function isArray(obj) {
		if (!Array.isArray) {
			return Object.prototype.toString.call(obj) === '[object Array]';
		} else {
			return Array.isArray(obj);
		}
	}

	/**
	 * Add a new subscriber callback
	 * @param {Object} subscriber Structured subscriber object
	 */
	function addSubscriber(eventName, subscriber) {
		var subscribers = catalog[eventName];

		/** Check if there are already any subscribers for this event */
		if (isArray(subscribers)) {
			/** Add to subscribers queue */
			subscribers.push(subscriber);
		} else {
			/** Create a new entry in the subscribers queue */
			catalog[eventName] = [subscriber];
		}
	}

	return {
		/**
		 * Publisher
		 * @param  {String} eventName Event to publish
		 */
		pub: function (eventName, args) {
			var length, i, subscriber,
			  subscribers = catalog[eventName],
			  definiteArgs = args || [];

			if (isArray(subscribers)) {
				for (i = 0, length = subscribers.length; i < length; i += 1) {
					subscriber = subscribers[i];

					if (subscriber && (typeof subscriber.callback === 'function')) {

						/** Execute callback function w/ defined context and arguments */
						subscriber.callback.apply(subscriber.context, definiteArgs);

						if (subscriber.once) {
							/** If this callback is only to be called once, remove it */
							subscribers.splice(i, 1);
							i -= 1; /* since we removed it from array, we need to move the pointer back one step */
						}
					}
				}
			}

			/** Make an entry in the log to preserve the latest state of this event (for any asap calls coming at a later time) */
			log[eventName] = definiteArgs;
		},

		/**
		 * Subscriber
		 * @param  {String}   eventName Event to subscribe to
		 * @param  {Function} callback  Function which to call on publish
		 */
		sub: function (eventName, callback, context) {
			addSubscriber(eventName, {
				callback: callback,
				context: context
			});
		},

		/**
		 * One time-subscription
		 * @param  {String}   eventName Event to subscribe to
		 * @param  {Function} callback  Function which to call on publish
		 */
		once: function (eventName, callback, context) {
			addSubscriber(eventName, {
				callback: callback,
				context: context,
				once: true
			});
		},

		/**
		 * Unsubscriber
		 * @param  {String} eventName Event which is to be removed from subscribers
		 */
		unsub: function (eventName, callback) {
			var i, length,
			  subscribers = catalog[eventName];

			if (typeof callback !== 'function') {
				/** If no callback was supplied, empty the whole stack */
				delete catalog[eventName];
			} else {
				/** Otherwise look up the specific callback and remove it */
				for (i = 0, length = subscribers.length; i < length; i += 1) {
					if (subscribers[i].callback === callback) {
						subscribers.splice(i, 1);
					}
				}
			}
		},

		/**
		 * As Soon As Possible
		 * If the event has already been triggered, execute callback right away
		 *
		 * @param  {String}   eventName Event name to subscribe to
		 * @param  {Function} callback  Function which to call on publish/asap
		 */
		asap: function (eventName, callback, context) {
			var trace = log[eventName];

			/** If there's an entry in the log for this event, call the callback and supply args if there are any */
			if (trace !== UNDEFINED) {
				callback.apply(context, (isArray(trace) ? trace : UNDEFINED));
			}

			/** And register a new subscriber */
			addSubscriber(eventName, {
				callback: callback,
				context: context
			});
		}
	};
});