define(['BankId/BankIdResources.js', 'utils/pubsub'], function (bankid, pubsub) {

    var addOrderRefToUrl = function (orderRef) {
        if (window.history && window.history.replaceState) {
            var searchWithoutOrderRef = getSearchWithoutOrderRef();
            var redirectQuery = (searchWithoutOrderRef ? searchWithoutOrderRef + '&' : '?') + 'orderRef=' + orderRef;
            window.history.replaceState(null, null, location.pathname + redirectQuery);
        }
    };

    var cleanUpUrlOrderRef = function () {
        if (window.history && window.history.replaceState && window.location.search !== searchWithoutOrderRef) {
            var searchWithoutOrderRef = getSearchWithoutOrderRef();
            window.history.replaceState(null, null, location.pathname + searchWithoutOrderRef);
        }
    }

    var getSearchWithoutOrderRef = function () {
        return window.location.search.replace(new RegExp("[?|&]orderRef=[^&;]+"), "");
    };

    //higher order function that should support starting both signs and auths
    var startBankIdTransaction = function (_this, initFunction, collectFunction) {
        return function () {
            pubsub.pub('BANKID_STATE_CHANGE', [{ progress: 'STARTING' }])
            var transaction = initFunction()
                .then(function (response) {
                    //Push the url BankID should redirect to into browser now, for "BankID back to same browser tab after sign" compability
                    addOrderRefToUrl(response.orderRef);
                    pubsub.pub('BANKID_STATE_CHANGE', [{ progress: "OUTSTANDING_TRANSACTION", autoStartToken: response.autoStartToken }]);
                    return collectBankIdTransaction(_this, collectFunction)(response.orderRef);
                });
            //this is a jQuery deferred, wrapping it in a promise, makes it work as a "real promise"
            return Promise.resolve(transaction).catch(function (err) {
                pubsub.pub('BANKID_STATE_CHANGE', [{ progress: err.progress || 'ERROR' }]);
            });
        };
    };

    //higher order function that should support collecting both signs and auths
    var collectBankIdTransaction = function (_this, collectFunction) {
        return function (orderRef) {
            // function for recursive polling, we have to pass a single promise resolve/reject here and we can't return a nested promise chain 
            // otherwise the resolver functions are called multiple times
            function delayedCollectCall(resolve, reject) {

                if (_this.cancelled) {
                    resolve('USER_CANCEL');
                    return;
                }
                collectFunction(orderRef).then(function (progress) {
                    pubsub.pub('BANKID_STATE_CHANGE', [{ progress: progress }])
                    //Do recursive call to handleCollect if bankid status is not signed, aborted or errorful
                    var shouldDoRecursiveCall = ['USER_SIGN', 'OUTSTANDING_TRANSACTION', 'NO_CLIENT'].indexOf(progress) >= 0;
                    if (!shouldDoRecursiveCall) {
                        //break the recursion chain
                        resolve({ progress: progress, orderRef: orderRef });
                        return;
                    }
                    window.setTimeout(function () {
                        delayedCollectCall(resolve, reject);
                    }, 2000)
                }, function (err) {
                    reject(err)
                });

            }
            //Create a promise for collecting and send its resolve and reject to delayedCollectCall
            return new Promise(function (resolve, reject) {
                delayedCollectCall(resolve, reject);
            }).catch(function (err) {
                var progress = err.progress || 'ERROR';
                pubsub.pub('BANKID_STATE_CHANGE', [{ progress: progress }]);
                return progress;
            }).then(function (progress) {
                cleanUpUrlOrderRef();
                return progress;
            });
        }
    };

    var BankIdTransaction = function () {
        var _this = this;
        _this.cancelled = false;
        pubsub.sub('BANKID_CANCEL', function () {
            _this.cancelled = true;
        });
    };

    BankIdTransaction.prototype.collectSign = function (applicationId, orderRef) {
        pubsub.pub('BANKID_STATE_CHANGE', [{ progress: 'STARTING' }]);
        return collectBankIdTransaction(
            this,
            function (orderRefInner) { return bankid.collectSign(applicationId, orderRefInner) })(orderRef);
    };

    BankIdTransaction.prototype.collectAuth = function (orderRef) {
        pubsub.pub('BANKID_STATE_CHANGE', [{ progress: 'STARTING' }]);
        return collectBankIdTransaction(
            this,
            function (orderRefInner) { return bankid.collectAuth(orderRefInner) })(orderRef);
    };

    BankIdTransaction.prototype.sign = function (applicationId) {
        return startBankIdTransaction(
            this,
            function () {
                return bankid.sign(applicationId);
            },
            function (orderRef) {
                return bankid.collectSign(applicationId, orderRef)
            })();
    };

    BankIdTransaction.prototype.auth = function (personalNumber) {
        return startBankIdTransaction(
            this,
            function () {
                return bankid.auth(personalNumber)
            },
            bankid.collectAuth)();
    };

    return BankIdTransaction;
});