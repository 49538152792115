define(['knockout', 'resources/raiseAmountSliderViewModel.js', 'utils/pubsub'], function (ko, raiseAmountViewModelLoad, pubsub) {
    return {
        init: function () {
            var _this = this;
            raiseAmountViewModelLoad.then(function (RaiseAmountViewModel) {
                ko.applyBindings(new RaiseAmountViewModel(_this.settings), _this.el);
                window.setTimeout(function () {
                    pubsub.pub('updaterangeslider', [true]);
                }, 1000);
            });
        }
    };
});