define('modules/postBeforeSubmit', [
	'jquery'], function ($) {
	    'use strict';
	    var postBeforeSubmit = {
	        init:function($element, settings)
	        {
	            $element.click(function (event) {
	                event.preventDefault();
	                try{
	                    $.ajax({
	                        url: settings.url,
	                        data: settings.data,
	                        type: 'POST'
	                    }).always(function (data) {
	                        $(settings.form).submit();
	                    });
	                }
	                catch(error)
	                {
	                    $(settings.form).submit();
	                }
	            });
	        },
	        defaults: {
	            url: '',
	            form: '',
	            data: {}
	        },
	    };
	    return postBeforeSubmit;
	});