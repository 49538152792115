define(['utils/pubsub', 'BankId/BankIdTransaction.js'], function (pubsub, BankIdTransaction) {
    var self = {
        defaults: {
            applicationId: null,
            event: 'submit-done',
            preventDefault: true,
            jqueryValidate: true,
            orderRef: null,
            useRedirect: false,
            autoLaunch: false,
        },

        init: function () {
            var self = this;
            if (self.settings.applicationId == null) {
                throw new Error('No application id set on BankIdSign');
            }
            //setup event listener
            self.$el.on(self.settings.event + '.bankidSign', function (event) {
                if (event && self.settings.preventDefault) {
                    event.preventDefault();
                }
                self.startSignProcess(self);
            });

            //if orderRef is set, start sign process directly 
            if (self.settings.orderRef) {
                //also show loader directly
                pubsub.pub('BANKID_STATE_CHANGE', [{ progress: 'STARTING' }]);
                self.startSignProcess(self, self.settings.orderRef);
            }
        },

        startSignProcess: function (self, orderRef) {
            var bankIdSign = new BankIdTransaction();

            //If the user enters the page with an orderRef already set, start collecting immediatly
            var bankIdAction = orderRef != null
                ? function () {
                    return bankIdSign.collectSign(
                        self.settings.applicationId,
                        self.settings.orderRef);
                }
                //else, perform new signrequest
                : function () {
                    return bankIdSign.sign(
                        self.settings.applicationId,
                        self.settings.autoLaunch,
                        self.settings.useRedirect);
                };

            bankIdAction().then(function (result) {

                if (result && result.progress == 'COMPLETE') {
                    pubsub.pub('LOAN_APPLICATION_STATUS_CHANGE');
                }
            });
        }
    };
    return self;
});