/// <reference path="/Assets/Cashbuddy/scripts/vendor/require.js" data-main="/Assets/Cashbuddy/scripts/main.js" />
define('modules/rangesliderinput', [
	'jquery',
    'utils/pubsub',
    'rangeslider',
], function ($, pubsub) {
    'use strict';

    var rangeSliderModule =  {
        // $el
        // settings
        defaults: {
            useInputClass: true,
            polyfill: true,
            disabledClass: "is-disabled",
            rangeClass: "Slide--js",
            verticalClass: "Slide--vertical",
            horizontalClass: "Slide--horizontal",
            fillClass: "Slide-track",
            handleClass: "Slide-thumb"
        },
        init: function ($el, settings, deferred) {
            $el.rangeslider({
                rangeClass: settings.useInputClass ? $el.attr('class') + ' ' + settings.rangeClass : settings.rangeClass,
                polyfill: settings.polyfill,
                disabledClass: settings.disabledClass,
                verticalClass: settings.verticalClass,
                horizontalClass: settings.horizontalClass,
                fillClass: settings.fillClass,
                handleClass: settings.handleClass
            });
            pubsub.sub('updaterangeslider', function (updateAttributes) {
                //rangeslider.js wont check that rangeslider has run and update will explode on non initiliazed element
                // therefore this hacky way of checking that it may be init
                if ($el.attr('style') && $el.attr('style').indexOf('opacity:0')) {
                    $el.rangeslider('update', updateAttributes)
                }
            });
        },
    };
    return rangeSliderModule;
});