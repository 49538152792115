define(['jquery', 'utils/pubsub'], function ($, pubsub) {

    return {
        defaults: {
            hideElement: null,
            sendAutoStartToken: true,
            language: 'sv-SE',
            autoStart: true,
            transactionType: null
        },

        previousBankIdState: null,

        init: function () {
            var self = this;
            self.startingHtml = self.$el.html();
            pubsub.sub('BANKID_STATE_CHANGE', function (pubData) { self.displayBankIdState.call(self, pubData); });
            self.$el.on('click', '.js-abort', function (event) {
                event.preventDefault();
                pubsub.pub("BANKID_CANCEL");
                $(self.settings.hideElement).removeClass('u-hidden');
                self.$el.addClass('u-hidden');
            })
        },

        displayBankIdState: function (pubData) {
            var self = this;
            //if someone else(bankidauth) disabled us, do nothing.
            if (self.$el.data('enabled') === false) {
                return;
            }
            if (!pubData.progress || pubData.progress === this.previousBankIdState) {
                return;
            }
            this.previousBankIdState = pubData.progress;
            $(this.settings.hideElement).addClass('u-hidden');
            $(this.$el).html(self.startingHtml).removeClass('u-hidden');
            $.get('/ContentRendering/BankIdMessage', {
                progress: pubData.progress,
                autoStartToken: pubData.autoStartToken,
                language: encodeURIComponent(self.settings.language),
                autoStart: self.settings.autoStart,
                transactionType: self.settings.transactionType
            }).then(function (html) {
                //check that no one was faster than current call
                if (self.previousBankIdState === pubData.progress) {
                    self.$el.html(html);
                }
                if (self.$el.find('.js-bankIdInit').length > 0) {
                    $('<iframe>').attr({ src: self.$el.find('.js-bankIdInit').attr('href') }).addClass('u-hidden').appendTo(self.$el);
                }
            });
        },
    };
});