/*Listens for a native event and translates it to a pubsub one*/
define([
    'jquery',
    'utils/pubsub',
], function ($, pubsub) {
    'use strict';
    return {
        defaults: {
            event: undefined,
            pubEvent: undefined,
            publish: undefined,
            element: null,
            publishData: null
        },
        init: function () {
            var self = this;
            var listenElement = self.settings.element || self.$el;
            $(listenElement).on(self.settings.event, function () {
                pubsub.pub(self.settings.publish, self.settings.publishData);
            })
            if (self.settings.pubEvent) {
                pubsub.sub(self.settings.pubEvent, function () {
                    pubsub.pub(self.settings.publish, self.settings.publishData);
                })
            }
        },
    };
});