define([], function () {
    'use strict';
    var dataLayerPush = {
        defaults: {
            event: 'click',
            preventDefault: false,
            data: {},
        },
        init: function () {
            var _this = this;
            //if event is set to init, push directly, otherwise wait for event
            _this.settings.event === 'init'
                ? _this.pushToDataLayer()
                : _this.$el.on(_this.settings.event, function (e) {
                    if (_this.settings.preventDefault) {
                        e.preventDefault();
                    }
                    _this.pushToDataLayer.call(_this);
                });
        },

        pushToDataLayer: function () {
            var _this = this;
            //if datalayer is not available, don't do anything;
            if (!('dataLayer' in window) || typeof dataLayer.push !== "function") {
                return;
            }
            //if data is array push all items in seq. else push whatever directly
            _this.settings.data && _this.settings.data.constructor === Array
                ? _this.settings.data.map(function (dataItem) { dataLayer.push(dataItem) })
                : dataLayer.push(_this.settings.data);
        }
    };
    return dataLayerPush;
});