System.config({
    baseURL: '/Assets/scripts/',
    paths: {
        'utils/': 'utilities/',
        'modules/typescript/*.js': 'modules/typescript/*.ts',
        'bankid': 'bankid/bankid.js',
        "modules/KalpForm.js": "loanapplication/KalpForm.js",
        "modules/OfferOption.js": "loanapplication/OfferOption.js",
        "modules/ApplicationStatusPoller.js": "loanapplication/ApplicationStatusPoller.js",
        "modules/ComplementStateHandler.js": "loanapplication/ComplementStateHandler.js",
        "modules/ComplementForm.js": "loanapplication/ComplementForm.js"
    },
    map: {
        "app.js": "app.js",
        "modules/LoanApplication.js": "loanapplication/LoanApplication.js",
        "modules/Otherloans.js": "loanapplication/Otherloans.js",
        'jquery.slider': 'vendor/jquery.slider.js',
        'jquery': 'CDN/jquery-1.11.3.min.js',
        'underscore': 'vendor/underscore.js',
        'knockout': 'vendor/knockout-2.1.0.js',
        'rangeslider': 'vendor/rangeslider.js',
        'jquery.simplemodal': 'vendor/jquery.simplemodal.js',
        'tmpl': 'vendor/tmpl.js',
        'moduler': 'vendor/moduler-1.0.0.js',
        'utils/pubsub': 'utilities/pubsub.js',
        'jquery.validate.unobtrusive': 'vendor/jquery.validate.unobtrusive.MODIFIED.js',
        'jquery.validate.unobtrusive.ajax': 'vendor/jquery.unobtrusive-ajax.js',
        'jquery.validate': 'vendor/jquery.validate.MODIFIED.js',
        "typescript": "node_modules/typescript",
    },
});