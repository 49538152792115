
/// <reference path='/Assets/Cashbuddy/scripts/vendor/require.js' data-main='/Assets/Cashbuddy/scripts/main.js' />
define([
	'jquery',
  'jquery.simplemodal'
], function ($, modal) {
	'use strict';

	var Dialog = {
		defaults: {
		    url: '',
		    requestData: {},
			content: '',
			event: 'click'
		},
		init: function ($el, settings) {
		    var self = this,
				url = ($el.attr('href') != '#' && $el.attr('href')) || settings.url,
                loaderContent = '<div class="Loader u-textCenter"><img class="Loader-icon" src="/Assets/Images/loader.gif"></div>',
				modalSettings = {
					overlayClose: true,
					escClose: true,
					minWidth: '300px',
					maxWidth: '600px',
					minHeight: 'auto',
					closeHTML: '<a href="#" class="Dialog-close"></a>',
					onShow: function () {
					    $('#simplemodal-container').css({ 'height': 'auto' });
					    $('#simplemodal-container').addClass('Dialog');
                        $('.simplemodal-wrap').addClass('Dialog-wrap');
                        $('#simplemodal-data').addClass('Dialog-data');
                        $('#simplemodal-overlay').addClass('Overlay');
					    //we need simple modal to re-pos after applying stylesets above.
                        $(window).trigger('resize.simplemodal')
                        $(document.documentElement).css({ overflow: 'hidden' });
                        $('body').css({ overflow: 'scroll' });
                        $('#simplemodal-overlay').css({ width: '100%' });
					},
					afterClose: function () {
					    $(document.documentElement).add($('body')).css({ overflow: '' });
					    $('#simplemodal-overlay').css({ width: 'auto' });
					},
				}; 

			self.$el.on(settings.event, function (e) {
				e.preventDefault();

				if (settings.content.length) {
					$.modal(settings.content, modalSettings);
				} else {
				    $.modal(loaderContent, modalSettings);

				    var requestData = $el.attr('data-dialog-requestdata') ? JSON.parse($el.attr('data-dialog-requestdata')) : settings.requestData;
					$.get(url, requestData).done(function (data) {
					  $('#simplemodal-data').html(data);
					  $.modal.setContainerDimensions();
					});
				}
			});
		},

	};

	return Dialog;

});