define(['jquery', 'utils/pubsub'],
    function ($, pubsub) {
    'use strict';
    var moduleObj = {
        defaults: {
            subEvent: null,
            nativeEvent: null,
            disable: false,
            triggerEvent: 'formPoster-done',
            cascadeEventOnSuccess: false
        },

        init: function () {
            var self = this;
            if(self.settings.subEvent)
            {
                pubsub.sub(self.settings.subEvent, function () { moduleObj.postForm(self); });
            }
            else if(self.settings.nativeEvent){
                self.$el.on(self.settings.nativeEvent + '.formpost', function (event) {
                    event.preventDefault();
                    if (self.settings.cascadeEventOnSuccess) {
                        event.stopImmediatePropagation();
                    }
                    moduleObj.postForm(self);
                })
            }
            else
            {
                moduleObj.postForm(self);
            }
        },

        postForm: function (self) {
            var form = self.settings.formElement ? $(self.settings.formElement) : self.$el;
            return $.post(form.prop("action"), form.serialize())
                .then(function () {
                    if (self.settings.disable) {
                        self.$el.find('input').prop({ 'disabled': 'disabled' });
                        self.$el.off('.formpost');
                    }
                    if (self.settings.triggerEvent) {
                        self.$el.trigger(self.settings.triggerEvent);
                    }
                    if (self.settings.cascadeEventOnSuccess) {
                        self.$el.trigger(self.settings.nativeEvent);
                    }
                });
        }
    }
    return moduleObj;
});