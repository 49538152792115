define([
	'jquery',
    'utils/pubsub'
], function ($, pubsub) {
    'use strict';
    var moduleObj = {
        defaults: {
            keyCode: 13,
            triggerEvent: "click",
            triggerTarget: "",
        },
        init: function (module, settings) {
            var self = this;
            $(module).on("keydown", function (event) { moduleObj.listen.keydown(event, self); });
        },
        listen: {
            keydown: function (event, module) {
                if ('which' in event && event.which == module.settings.keyCode)
                {
                    event.preventDefault();
                    module.$element.find((module.settings.triggerElement)).trigger(module.settings.triggerEvent);
                }
            }
        }
    }
    return moduleObj;
});