define(['jquery', 'moduler'], function ($, Moduler) {
    'use strict';
    return {
        // $el
        // settings
        defaults: {
            url: undefined,
            targetElement: null,
            loadNewModules: true,
        },
        init: function () {
            var self = this;
            if (typeof self.settings.url === 'undefined') {
                return;
            }
            $.get(self.settings.url).done(function(data){
                var target = self.settings.targetElement != null
                    ? self.settings.targetElement : self.$el;
                $(target).html(data);
                if (self.settings.loadNewModules) {
                    Moduler.loadModules(target);
                }
            });
        }
    };
});