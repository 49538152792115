define([
	'jquery',
  'underscore',
  'utils/pubsub'
], function ($, _, pubsub) {
  'use strict';


  var Alert = function (settings) {
    settings = this.settings = $.extend({}, Alert.prototype.defaults, settings);
    this.init(settings);
  };
  Alert.prototype = {
    defaults: {
      topContainer: '<div class="AlertContainer js-alertContainer"></div>',
      template: '<div class="js-Alert Alert {1}">' +
        '<div class="Alert-content">{0}<span class="Alert-close js-close"><div class="Icon Icon--close"></div></span></div>' +
        '</div>',
      destination: null, // if no destination is set, it will be global popup
      maxErrors: 2
    },

    init: function (settings) {
      var self = this;
      _.bindAll(self, 'show', 'timeout', 'close');
    },

    show: function (text, timeout) {
      var self = this;
      if (!self.alert) {
        if (self.settings.destination) // inline error
        {
          if (self.settings.destination.find('.js-Alert').length >= self.settings.maxErrors)
            return;

          self.$alert = $(self.settings.template.replace('{0}', text).replace('{1}', 'Alert--inside'));
          self.$alert.fadeIn().appendTo(self.settings.destination);
        }
        else // global popup
        {
          if ($('.js-alertContainer').find('.js-Alert').length >= self.settings.maxErrors)
            return;

          self.$alert = $(self.settings.template.replace('{0}', text).replace('{1}', ''));
          if (!$('.js-alertContainer').length) {
            $(self.settings.topContainer).appendTo($("body"));
          }
          self.$alert.fadeIn().appendTo($(".js-alertContainer"));
        }
      }
      else {
        self.$alert.find('Alert-content').html(text);
      }
      self.$alert.on('click', '.js-close', self.close);
      if (timeout) {
        setTimeout(self.timeout, timeout)
      }
    },
    close: function () {
      var self = this;
      if (self.$alert)
        self.$alert.remove();
    },
    timeout: function () {
      var self = this;

      self.$alert.fadeOut();
      setTimeout(function () {
        self.$alert.remove();
        self.$alert = null;
      }, 1000);
    }
  };
  return Alert;
});
