define('modules/inputrounder',
    ['jquery'], function ($) {
        return {
            defaults: {
                precision: 1,
                targetElement: null,
                event: 'change',
                preventDefault: false,
            },
            init: function ($el, settings) {
                settings.$targetElement = settings.targetElement ? $(settings.targetElement) : $el;
                $el.on(settings.event, function (event) {
                    if (settings.preventDefault)
                    {
                        event.preventDefault();
                    }
                    var originalValue = $el.val();
                    roundedValue = Math.round(originalValue / settings.precision) * settings.precision;
                    settings.$targetElement.val(roundedValue);
                    if (settings.$targetElement != $el) {
                        settings.$targetElement.trigger('change');
                    }
                });
            }
        }
    });