define([
	  'jquery',
    'utils/pubsub',
    'utils/ErrorHandler.js'
], function ($, pubsub, ErrorHandler) {
  return {
    defaults: {
      authServicesSelector: ".js-authServices",
      postingFormSelector: ".js-onnistuuPostingForm",
      authContainerSelector: ".js-authServiceContainer",
      pinSelector: ".js-pin",
      overlaySelector: ".js-overlay",
      onnistuuformUrl: null
    },
    init: function ($el, settings) {
      var self = this;
      self.loadOnnistuuForm();

      $(self.$el).on('click', settings.authServicesSelector, function () {
        if (!$(settings.pinSelector).closest('form').valid()) {
          var inView = $(settings.pinSelector).parent().offset().top;
          var currentScroll = $(window).scrollTop();
          if (currentScroll > inView) {
            $('html, body').animate({
              scrollTop: $(settings.pinSelector).parent().offset().top
            }, 500);
          }
          return;
        }
        self.service = $(this).data('service');
        $(self.settings.overlaySelector).removeClass("u-hidden");
        $.get('/api/onnistuu/getOnnistuForm?pin=' + $(settings.pinSelector).val())
          .done(self.postOnnistuuForm.bind(self)) 
          .fail(self.error.bind(self));
      });
    },
    loadOnnistuuForm: function () {
      var self = this;

      $.get(self.settings.onnistuuformUrl)
          .done(function (data) {
            var authContainer = $(self.settings.authContainerSelector);
            authContainer.html(data);
          })
          .fail(self.error);
    },
    postOnnistuuForm: function (response) {
      var self = this;
      var $form = $(self.settings.postingFormSelector, self.$el);
      
      $form.find("[name=customer]").val(response.customerId);
      $form.find("[name=data]").val(response.data);
      $form.find("[name=iv]").val(response.iv);
      $form.find("[name=auth_service]").val(self.service);
      
      $form.submit();
    },
    error: function (fail) {
      var self = this;
      $(self.settings.overlaySelector).addClass("u-hidden");
    }
  };
});