
define([
	'jquery',
    'utils/pubsub',
    'knockout',
    'models/AmountSliderViewModel.js',
], function ($, pubsub, ko, AmountSliderViewModel) {
  'use strict';
  var amountSlider = {
    // $el
    // settings
    defaults: {
      amount: null,
      numberOfMonths: null,
      minMonths: 12,
      maxMonths: 36,
      stepMonths: 1,
      maxAmount: 3000,
      minAmount: 1000,
      stepAmount: 100,
      extendedBindElements: [],
      useExternalCalculation: false,
    },

    init: function ($el, settings) {
      var self = this;

      // create new knockout model
      self.knockoutModel = new AmountSliderViewModel(self.settings);
		
		// set default values
      if (!settings.amount)
        settings.amount = settings.maxAmount;

      if (!settings.numberOfMonths)
          settings.numberOfMonths = settings.maxMonths;

      ko.applyBindings(self.knockoutModel, $el.get(0));
      for (var index in self.settings.extendedBindElements)
      {
          ko.applyBindings(self.knockoutModel, $(self.settings.extendedBindElements[index]).get(0));
      }
      pubsub.pub('updaterangeslider', [true]);
    },
  };

  return amountSlider;

});