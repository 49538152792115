define('modules/formFileSubmit', [
    'jquery'], function ($) {
        'use strict';
        var formFileSubmit = {
            init: function ($element, settings) {
                var $form = settings.form ? $(settings.form) : $element,
                    $btn = $form.find('button[type="submit"]'),
                    $fileBox = $form.find('.FileBox');

                $fileBox.on('max-count', function (e) {
                    $btn.attr('disabled', false);
                });

                $fileBox.find('input').on('duplicate', function () {
                    $btn.attr('disabled', 'disabled');
                });

                $form.on('submit', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var method = $form.attr('method'),
                        action = $form.attr('action'),
                        data = new FormData(this); //needed for files

                    $form.addClass(settings.cssClass);
                    $form.find(settings.disableElements).prop('disabled', 'disabled');

                    $.ajax({
                        url: action,
                        type: method,
                        data: data,
                        cache: false,
                        dataType: 'html',
                        //encType: 'multipart/form-data',
                        processData: false, // Don't process the files
                        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
                    }).success(function (data) {
                        //if everything went ok, reload page
                        window.location.href = window.location.href;
                    }).error(function (data) {
                        console.log('something went wrong!');
                        $form.removeClass(settings.cssClass);
                        $form.find(settings.disableElements).prop('disabled', false);
                    }).always(function (data) {
                        console.log(data);
                    });


                });
            },
            defaults: {
                cssClass: 'Loading',
                event: 'submit',
                timeout: 1000,
                form: null,
                disableElements: 'button'
            },
        };
        return formFileSubmit;
    });