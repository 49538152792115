
define([
	'jquery',
	'utils/pubsub'
], function ($, pubsub) {
	'use strict';
	var stickyNav = {
		defaults: {
			transition: "",
            itemContainer: ".Plate",
            navOffsetTop: 100
		},
		init: function() {
			var self = this,
				top = $(window).scrollTop(),
				stickyNavPosDef = self.$el.offset().top;

			$(window).scroll(function() {
				top = $(window).scrollTop();
				self.scrollActions(self, top, stickyNavPosDef);
			});

			self.$el.css("transition-duration", self.settings.transition);
			self.$el.on('click', 'a[href*=#]:not([href=#])', self._navigateTo);
            pubsub.sub('controllers.ready', function () { stickyNav.scrollActions(self, top, stickyNavPosDef) });

            if (window.location.hash) {
                var link = window.location.hash;
                var target = $(link);
                var handler = $("li a[href='" + link + "']", self.$el);

                if (target.length == 1 && handler.length == 1) {
                    handler.trigger("click");
                }
            } else {
                $("li:first-child", self.$el).addClass("is-current");
            }
		},
		_navigateTo: function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$("html, body").animate({
						scrollTop: (target.offset().top)
					}, 300);
					return false;
				}
			}
		},
		scrollActions: function (self, top, stickyNavPosDef) {
			var $el = self.$el;
			stickyNav._scrollNav(self, $el, top, stickyNavPosDef);

			window.setTimeout(function() {
				stickyNav._setActiveLink($el, top, stickyNavPosDef);
			}, 400);
		},
		_scrollNav: function (self, $el, top, stickyNavPosDef) {
			var parentHeight = $(self.settings.itemContainer).outerHeight(),
				parentOffset = $(self.settings.itemContainer).offset().top;

            // start transitioning element if (NavOffsetTop)px from scrolltop
            if (top >= (stickyNavPosDef - self.settings.navOffsetTop)) {
				$el.css("top", top - 50);
			}
            // make sure element doesn't position itself less than (navOffsetTop)px from parent top
            if (top <= stickyNavPosDef) {
                $el.css("top", self.settings.navOffsetTop);
			}
			// stop element if exceeds height of parent
			if (top >= parentHeight - 400) {
				$el.css("top", parentHeight - 450);
			}
		},
		_setActiveLink: function ($el, top, stickyNavPosDef) {
			var linkItems = $el.find('li a'),
				stickyNavPos = $el.offset().top;

			// loop existing links and collect targetids
			linkItems.each(function(index, link) {
				var targetId = $(link).attr('href'),
					nextTargetId;

				if(index <= linkItems.length - 1) {
					var nextLink = linkItems[index + 1];
					nextTargetId = $(nextLink).attr('href');
                }
                if (stickyNavPos >= $(targetId).offset().top - 100 && (!nextTargetId || stickyNavPos < $(nextTargetId).offset().top)) {
					$el.find(linkItems).removeClass("is-current");
					$el.find(link).addClass("is-current");
				}
			});
		}
	};
	return stickyNav;
});