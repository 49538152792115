define('modules/dispatcher', [
	'jquery',
  'underscore',
  'utils/pubsub'
], function ($, _, pubsub) {
	'use strict';

	var moduleObj = {
		defaults: {
			listenEvent: 'click', // the event to listen for. (required)
			listenSelector: null, // selector for elements to listen for the event (optional)

			triggerEvent: '', // event name to trigger when listenEvent fires. (required)
			triggerSelector: null, // selector for elements to trigger the event. (optional)
			triggerContext: null, // the context in which it will try to find elements through triggerSelector. (optional)

			preventDefault: true // whether to preventDefault when event fires.
		},

		init: function ($el, settings, deferred) {
			$el.on(settings.listenEvent, settings.listenSelector, this, moduleObj.eventFired);
		},

		eventFired: function (e) {
			var module = e.data,
				settings = module.settings;

			if (settings.preventDefault) {
				e.preventDefault();
			}

			if (settings.triggerSelector) {
				var $container = moduleObj.getContext(module);
				$container.find(settings.triggerSelector).trigger(settings.triggerEvent);
			} else {
				module.$element.trigger(settings.triggerEvent);
			}
		},

		getContext: function (module) {
			var triggerContext = module.settings.triggerContext;

			if (triggerContext) {
				if (triggerContext == 'parent') {
					return module.$element.parent();
				}
				return $(triggerContext);
			}
			return module.$element;
		},

		destroy: function (module) {
			module.$element.off(settings.listenEvent, moduleObj.eventFired);
		}
	};
	return moduleObj;

});